import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import scroll from 'vue-seamless-scroll';
import config from "@/config/index.js";
Vue.config.productionTip = false;
Vue.use(scroll);

//px按照屏幕缩放
Vue.prototype.$adjustSize=function(num){
    let docEl = document.documentElement,
    clientWidth = window.innerWidth || 
    document.documentElement.clientWidth ||
    document.body.clientWidth;
    if(!clientWidth) return ;
    let adjustSize = (clientWidth /  1920);
    return num * adjustSize;
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
